import { env } from 'src/env';
import { isEnterprise } from 'src/utils';

const FOOTER_LINK = env.REACT_APP_NINJA_SHARED_SIGNUP_URL;

export const Footer = () => {
  return (
    <div className="nj-footer">
      <a
        href={FOOTER_LINK}
        target="_blank"
        rel="noreferrer"
        className="nj-button nj-button--primary"
      >
        {isEnterprise ? 'Try Ninja' : 'Try Ninja for $5/mo'}
      </a>
      <p>
        Ninja is currently in beta and can make mistakes. Please do not use it
        for mission critical tasks.
      </p>
    </div>
  );
};
